@charset "utf-8";
/* 
@font-face {
	font-family: 'cervoneue_black';
	src: url('./Assets/Fonts/NanumGothicExtraBold.eot');
	src: url('./Assets/Fonts/cervoneue_black.woff') format('woff'),
	url('./Assets/Fonts/cervoneue_black.woff2') format('woff2');
} */

@font-face {
  font-family: 'MontrealTS';
  font-weight: bold;
  src: url('./Assets/Fonts/MontrealTS-HeavyIta.otf');
  src: url('./Assets/Fonts/MontrealTS-HeavyIta.woff') format('woff'),
    url('./Assets/Fonts/MontrealTS-HeavyIta.woff2') format('woff2');
}

/*@font-face {
  font-family: 'sofia_pro_bold';
  src: url('./Assets/Fonts/NanumGothicExtraBold.eot');
  src: url('./Assets/Fonts/sofia_pro_bold.woff') format('woff'),
    url('./Assets/Fonts/sofia_pro_bold.woff2') format('woff2');
}

@font-face {
  font-family: 'sofia_pro_black';
  src: url('./Assets/Fonts/NanumGothicExtraBold.eot');
  src: url('./Assets/Fonts/sofia_pro_black.woff') format('woff'),
    url('./Assets/Fonts/sofia_pro_black.woff2') format('woff2');
}*/
/*Gatorade Fonts*/
@font-face {
  font-family: 'gatorade';
  font-weight: 300;
  src: url('./Assets/Fonts/Gatorade-Light.eot');
  src: url('./Assets/Fonts/Gatorade-Light.woff') format('woff')
}
@font-face {
  font-family: 'gatorade';
  font-weight: 400;
  src: url('./Assets/Fonts/Gatorade-Med.eot');
  src: url('./Assets/Fonts/Gatorade-Med.woff') format('woff')
}
@font-face {
  font-family: 'gatorade';
  font-weight: 500;
  src: url('./Assets/Fonts/Gatorade-Bold.eot');
  src: url('./Assets/Fonts/Gatorade-Bold.woff') format('woff')
}
@font-face {
  font-family: 'gatorade';
  font-weight: 600;
  src: url('./Assets/Fonts/Gatorade-Black.eot');
  src: url('./Assets/Fonts/Gatorade-Black.woff') format('woff')
}

@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 100;
  src: url('./Assets/Fonts/NotoSans-Thin.eot') format('eot');
  src: url('./Assets/Fonts/NotoSans-Thin.woff') format('woff'),
    url('./Assets/Fonts/NotoSans-Thin.woff2') format('woff2');
}
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 300;
  src: url('./Assets/Fonts/NotoSans-Light.eot') format('eot');
  src: url('./Assets/Fonts/NotoSans-Light.woff') format('woff'),
    url('./Assets/Fonts/NotoSans-Light.woff2') format('woff2');
}
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 400;
  src: url('./Assets/Fonts/NotoSans-Regular.eot') format('eot');
  src: url('./Assets/Fonts/NotoSans-Regular.woff') format('woff'),
    url('./Assets/Fonts/NotoSans-Regular.woff2') format('woff2');
}
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 500;
  src: url('./Assets/Fonts/NotoSans-Medium.eot') format('eot');
  src: url('./Assets/Fonts/NotoSans-Medium.woff') format('woff'),
    url('./Assets/Fonts/NotoSans-Medium.woff2') format('woff2');
}
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 700;
  src: url('./Assets/Fonts/NotoSans-Bold.eot') format('eot');
  src: url('./Assets/Fonts/NotoSans-Bold.woff') format('woff'),
    url('./Assets/Fonts/NotoSans-Bold.woff2') format('woff2');
}
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 900;
  src: url('./Assets/Fonts/NotoSans-Black.eot') format('eot');
  src: url('./Assets/Fonts/NotoSans-Black.woff') format('woff'),
    url('./Assets/Fonts/NotoSans-Black.woff2') format('woff2');
}

@import url('https://fonts.googleapis.com/css2?family=Catamaran:wght@100&display=swap');
* {
  margin: 0;
  padding: 0;
}

li {
  list-style-type: none;
}

a {
  text-decoration: none;
  color: #000;
  display: block;
}

img {
  vertical-align: bottom;
}

:root {
  --theme-color: #004caf;
  --gray-color: #dadae0;
}

body {
  font-family: 'sofia_pro_bold', 'noto sans kr', '맑은 고딕', 'Malgun Gothic',
    sans-serif;
  color: #242424;
  font-size: 16px;
  background-color: #fafafa;
}

.gatorade {
  font-family: 'gatorade', 'noto sans kr', '맑은 고딕', 'Malgun Gothic',
  sans-serif !important;
}

.gatorade > * {
  font-family: 'gatorade', 'noto sans kr', '맑은 고딕', 'Malgun Gothic',
  sans-serif !important;
}

header,
nav,
section,
main,
footer {
  display: block;
}

/* form */
button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font: inherit;
  color: inherit;
  outline: none;
  width: 100%;
}

button {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html input[type='button'],
input[type='reset'],
input[type='submit'] {
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled],
html input[disabled] {
  cursor: default;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  padding: 0;
  border: 0;
}

input {
  line-height: normal;
  height: 50px;
  border: 0;
  border-bottom: solid 1px #aaa;
}

input[type='checkbox'],
input[type='radio'] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  height: auto;
}

input[type='search'] {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  -webkit-appearance: textfield;
}

input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

fieldset {
  padding: 0.35em 0.625em 0.75em;
  margin: 0 2px;
  border: 1px solid #c0c0c0;
}

legend {
  padding: 0;
  border: 0;
}

textarea {
  overflow: auto;
}

select {
  background-color: #f0f0f0;
  border: 0;
  color: #555;
  font-size: 0.875rem;
  height: 40px;
  padding: 0 10px;
  margin-top: 5px;
}

select.normal-select {
  background-color: #ffffff;
  border: 0;
  color: #555;
  font-size: 1rem;
  height: 50px;
  padding: 0 10px;
  margin-top: 0px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin-right: 10px;
}

optgroup {
  font-weight: bold;
}

label {
  display: block;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

td,
th {
  padding: 0;
}

::placeholder {
  color: #ccc;
}

/* form end */

/* design style */

h1 {
  font-family: 'MontrealTS', 'noto sans kr', '맑은 고딕', 'Malgun Gothic',
    sans-serif !important;
  font-size: 1.25rem;
  text-transform: uppercase;
  font-weight: bold;
}

h2 {
  font-family: 'MontrealTS', 'noto sans kr', '맑은 고딕', 'Malgun Gothic',
    sans-serif !important;
  font-size: 1rem;
  font-weight: bold;
  line-height: 50px;
  text-transform: uppercase;
}

h3 {
  font-family: 'MontrealTS', 'noto sans kr', '맑은 고딕', 'Malgun Gothic',
    sans-serif !important;
  font-size: 0.875rem;
  text-transform: uppercase;
  font-weight: bold;
}

h4 {
  font-size: 0.75rem;
}

p,
ul,
li,
a,
span {
  font-size: 1.25rem;
}

.clear::after {
  content: '';
  display: block;
  clear: both;
}

.btn-area {
  padding-top: 30px;
  text-align: center;
}

.btn {
  line-height: 70px;
  border: 0;
  font-size: 1.125rem;
  display: inline-block;
  font-weight: bold;
  box-sizing: border-box;
  width: 50%;
  text-align: center;
}

.btn-pt {
  background: #000;
  color: #fff;
}

.input-with-btn {
  position: relative;
}

.input-with-btn input {
}

.input-with-btn .check {
  position: absolute;
  right: 0;
  bottom: 5px;
  background-color: #f0f0f0;
  border: solid 1px #ddd;
  width: 100px;
  height: 49px;
}

.divide2 {
}

.divide2::after {
  content: '';
  display: block;
  clear: both;
}

.divide2 > * {
  width: 50%;
  float: left;
}

.divide5 {
}

.divide5::after {
  content: '';
  display: block;
  clear: both;
}

.divide5 > * {
  width: 19%;
  float: left;
  margin-right: 1%;
}

.color_blue {
  color: var(--theme-color);
}

.bg_blue {
  background-color: var(--theme-color);
}

.rc {
  border-radius: 6px;
  overflow: hidden;
}

.shadow {
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

/* design style end */

.wrapper {
  max-width: 1280px;
  width: 90%;
  margin: 0 auto;
}

.wrapper_wide {
  max-width: 1600px;
  margin: 0 auto;
}

.popup-wrap {
  border: solid 10px black;
}

.pfcenter {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
}

.flex {
  display: flex;
  flex-wrap: wrap;
}

.justify-center {
  justify-content: center;
}

.justify-space {
  justify-content: space-between;
}

.align-center {
  align-content: center;
}

/* intro */
/* .full_cover {
	background-color: #fff;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 9999;
} */

/* .fc_inner {
	text-align: center;
	height: auto;
} */
/* 
.fc_header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	padding: 2%;
	box-sizing: border-box;
} */

.intro_img {
  max-width: 200px;
  animation: rotate360 0.5s;
}

.intro_txt {
  font-size: 5rem;
  margin-top: 2rem;
}

@keyframes rotate360 {
  from {
    transform: rotateY(0);
  }

  to {
    transform: rotateY(360deg);
  }
}

/* gnb */
.gnb_wrap {
  position: relative;
}

.sns {
  position: absolute;
  top: 50%;
  right: 0;
  transform: rotate(90deg) translateX(50%);
  transform-origin: 100% 0 0;
}

.sns li a {
  padding: 40px 20px;
}

.s_header {
  color: #fff;
  padding: 10rem 0 5rem;
  font-size: 2rem;
}

.s_header.color_black {
  color: #000;
}

.s_title {
  font-size: 2rem;
  /* padding-left:20px; */
}

.sp_title {
  font-size: 3.4rem;
  margin-top: 3rem;
  line-height: 1.2;
}

.s_cont {
}

.s_cont.transparent {
  background-color: transparent;
}

.history {
  background: url(/Img/bg_pattern2.jpg) center top fixed;
  background-size: cover;
}

.half_bg {
}

.change_page {
  background-color: transparent;
}

.change_bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  animation: show_change 0.5s both, show_change_after 0.5s 2s both;
}

.bg_blue2 {
  background-color: red;
  z-index: 99;
  animation-delay: 0.2s, 1.5s;
}

.bg_blue3 {
  background-color: orange;
  z-index: 100;
  animation-delay: 0.4s;
}

@keyframes show_change {
  from {
    height: 0;
  }

  to {
    height: 100%;
  }
}

@keyframes show_change_after {
  from {
    top: 0;
  }

  to {
    top: 100%;
  }
}

/* .change_page img{ position:absolute; width:90%; }
.change_page img.change_red{ top:0; left:0; transform-origin:0 0 0; animation:show_red 0.8s both; }
.change_page img.change_blue{ bottom:0; right:0; transform-origin:100% 100% 0; animation:show_red 0.8s both; }

@keyframes show_red{
	from{ transform:scale(0); }
	to{ transform:scale(1); }
} */

/* slide */
.slide_mv {
  padding-bottom: 5rem;
}

.half_slide {
}

.half_slide .slide_inner {
  position: relative;
  overflow: hidden;
  text-align: center;
}

.half_slide .slide_inner .year {
  position: absolute;
  right: -1rem;
  bottom: -5.5rem;
  font-size: 10rem;
  color: var(--gray-color);
  z-index: 1;
}

.half_slide .slide_inner img {
  width: 80%;
  z-index: 99;
}

.half_slide .slide_inner {
  padding: 100px;
  background-color: #fff;
  border-radius: 10px;
}

.half_slide .slide_inner.nopadding {
  padding: 0;
}

/* tab */
.tab {
  margin-top: 2rem;
}

.tab a {
  display: inline-block;
  color: var(--gray-color);
  border-radius: 300px;
  padding: 8px 30px;
  font-size: 1rem;
}

.tab a.active {
  color: #fff;
  background-color: var(--theme-color);
}

/* card */
.card_box {
}

.card {
  overflow: hidden;
  position: relative;
  max-width: 32%;
  margin-bottom: 2%;
}

.card img {
  width: 100%;
}

.card_title {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
  padding: 2rem;
  color: #fff;
  box-sizing: border-box;
  font-size: 1.2rem;
}

.card_s {
  max-width: 19%;
  margin-bottom: 2%;
}

.card_s img {
  width: 100%;
}

.sns_wrap {
  margin-top: 3rem;
  padding-bottom: 3rem;
}

.mi_title {
  font-size: 1.6rem;
  line-height: 2rem;
  margin-bottom: 2rem;
}

/* history_detail */
.card_big_img {
  max-width: 360px;
  margin: 0 auto;
}

.card_big_img img {
  width: 100%;
  /* transform:translateY(50px); */
}

.card_wrap {
  padding: 100px 0 150px;
}

.card2 {
  width: 48%;
}

.card2 img {
  width: calc(100% - 2px);
  border: solid 1px var(--gray-color);
  margin-bottom: 2rem;
}

.card2 p {
  text-align: justify;
}

.pl20 {
  padding-left: 40%;
}

.pr20 {
  padding-right: 40%;
}

.pt20 {
  padding-top: 100px;
}

.txt_point_s {
  display: block;
  font-size: 4rem;
  line-height: 1;
  margin-top: 140px;
}

.txt_point_rotate {
  display: block;
  font-size: 1.4rem;
  /* transform-origin:50% 50% 0; */
  transform: rotate(90deg) translate(140px, -50px);
}

.txt_point_rotate strong {
  margin-top: 2rem;
  display: block;
  font-size: 4rem;
  line-height: 1;
}

.zoom_wrap {
  background-color: #fff;
  max-height: 700px;
  overflow: hidden;
  position: relative;
}

.zoom_wrap img {
  margin-left: 6%;
}

.zoom_wrap .zoom_txt_area {
  position: absolute;
  width: 320px;
  padding: 50px;
  top: 50%;
  right: 10%;
  z-index: 9;
  transform: translateY(-50%);
}

.zoom_wrap .zoom_txt_area h4 {
  font-size: 4rem;
  line-height: 1;
}

.zoom_wrap .zoom_txt_area span {
  font-size: 1.4rem;
  display: block;
  margin-bottom: 1rem;
}

.zoom_wrap .zoom_img01 {
  transform: translate(0, -350px);
}

.zoom_wrap .zoom_img02 {
  transform: scale(0.4) translateY(10%);
  transform-origin: 50% 0 0;
}

.zoom_wrap .zoom_img03 {
  animation: show_pakg 10s infinite alternate linear both;
}

@keyframes show_pakg {
  from {
    transform: translateY(100px);
  }

  to {
    transform: translateY(-50%);
  }
}

.zoom_slide_wrap {
  width: 320px;
  overflow: hidden;
}

#zoom_txt_slide {
  width: 960px;
  transition: all 0.5s;
}

#zoom_txt_slide::after {
  content: '';
  display: block;
  clear: both;
}

#zoom_txt_slide.move0 {
  transform: translateX(0);
}

#zoom_txt_slide.move1 {
  transform: translateX(-320px);
}

#zoom_txt_slide.move2 {
  transform: translateX(-640px);
}

#zoom_txt_slide li {
  float: left;
  width: 320px;
}

.zoom_nav li {
  width: 10px;
  height: 10px;
  background-color: var(--gray-color);
  display: inline-block;
  border-radius: 100px;
}

.zoom_nav li.on {
  background-color: #000;
}

/* brand */
.title_box {
  padding: 2% 5% 0 5%;
}

.title_box .s_title {
  margin-bottom: 3rem;
}

.title_box .title_year {
  font-size: 5rem;
}

.img_thumb {
  margin-top: 4rem;
}

.img_thumb li {
  max-width: 100px;
  display: inline-block;
  border: solid 1px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}

.img_thumb li.on {
  border: solid 3px var(--theme-color);
}

.img_thumb li.on img {
  opacity: 1;
}

.img_thumb li img {
  width: 100%;
  opacity: 0.8;
}

.img_box {
  border: solid 1px rgba(0, 0, 0, 0.1);
  width: 40%;
  margin-left: 10%;
}

.img_box img {
  width: 100%;
}

.section.animation h2 {
  animation: show_move 0.4s both;
}

.section.animation h3 {
  animation: show_move 0.4s 0.2s both;
}

.section.animation p {
  animation: show_move 0.4s 0.4s both;
}

.section.animation .img_thumb li:nth-child(1) {
  animation: show_move 0.4s 0.6s both;
}

.section.animation .img_thumb li:nth-child(2) {
  animation: show_move 0.4s 0.8s both;
}

.section.animation .img_thumb li:nth-child(3) {
  animation: show_move 0.4s 1s both;
}

@keyframes show_move {
  from {
    opacity: 0;
    transform: translateY(-30px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* fullpage nav txt */
/* #fp-nav ul li, .fp-slidesNav ul li{ width:auto; height:auto; }
#fp-nav ul li a span{ margin-top:5px; }
#fp-nav ul li a.active span{ margin-top:5px; width:30px; }
#fp-nav ul li a:hover span{ margin-top:5px !important; }
#fp-nav ul li a::after{ margin-left:20px; }
#fp-nav ul li:nth-child(1) a::after{ content:'1940'; }
#fp-nav ul li:nth-child(2) a::after{ content:'1950'; }
#fp-nav ul li:nth-child(3) a::after{ content:'1960'; }
#fp-nav ul li:nth-child(4) a::after{ content:'1980'; }
#fp-nav ul li:nth-child(5) a::after{ content:'1990'; }

#fp-nav ul li, .fp-slidesNav ul li{ margin:20px; } */

@media all and (max-width: 960px) {
  p,
  ul,
  li,
  a,
  span {
    font-size: 1rem;
  }

  .h_innder,
  .fc_header {
    padding: 5%;
  }

  .logo,
  fc_header .logo {
    width: 30px;
    height: 30px;
  }

  .icon_gnb,
  .icon_close {
    padding: 0;
  }

  .icon_gnb .list_icon,
  .icon_close .list_icon {
    left: 3px;
    top: 12px;
  }

  .gnb_list li a {
    font-size: 2.4rem;
  }

  .sns {
    position: static;
    transform: rotate(0) translateX(0);
    margin-top: 4rem;
  }

  .sns li a {
    padding: 20px;
  }

  .title_box .s_title {
    margin-bottom: 0;
    font-size: 1rem;
  }

  .title_box .title_year {
    font-size: 3rem;
  }

  .title_box p {
    font-size: 0.875rem;
  }

  .img_thumb {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .img_thumb li {
    max-width: 40px;
  }

  .img_box {
    margin-left: 0;
    width: 100%;
    border: 0;
  }

  .half_slide .slide_inner {
    padding: 30px;
  }

  .s_title {
    font-size: 1.4rem;
  }

  .s_header {
    padding: 4rem 0 2rem;
  }

  .sp_title {
    font-size: 2rem;
    margin-top: 1rem;
  }

  .btn {
    width: 100%;
  }

  .slide_mv {
    padding-bottom: 0;
  }

  .tab {
    margin-top: 0;
  }

  .card_box {
    display: block;
  }

  .card {
    max-width: 100%;
  }

  .sns_wrap .card_box {
    display: flex;
  }

  .card_s {
    max-width: 49%;
  }

  .card_big_img {
    max-width: 200px;
  }

  .card_wrap {
    display: block;
    padding: 50px 0;
  }

  .card2 {
    width: 100%;
  }

  .card2 img {
    margin-bottom: 0;
  }

  .txt_point_s {
    display: none;
  }

  .txt_point_rotate {
    transform: rotate(0) translate(0);
    padding: 0 30px;
  }

  .txt_point_rotate strong {
    font-size: 2.2rem;
    margin-top: 1rem;
    margin-bottom: 30px;
  }

  .pl20 {
    padding-left: 0;
    padding: 30px;
  }

  .pr20 {
    padding-right: 0;
    padding: 30px;
  }

  .pt20 {
    padding-top: 0;
  }

  .zoom_wrap {
    max-height: 400px;
  }

  .zoom_wrap img {
    width: 90%;
    margin-left: 5%;
  }

  .zoom_wrap .zoom_txt_area {
    box-shadow: 0 -10px 20px rgba(0, 0, 0, 0.1);
    background-color: rgba(255, 255, 255, 1);
    width: 100%;
    padding: 5%;
    box-sizing: border-box;
    right: 0;
    top: auto;
    bottom: 0;
    transform: translateY(0);
  }

  .zoom_wrap .zoom_txt_area span {
    margin-bottom: 0;
    font-size: 1rem;
  }

  .zoom_wrap .zoom_txt_area h4 {
    font-size: 2rem;
  }

  .zoom_wrap .zoom_txt_area br {
    display: none;
  }

  .zoom_nav {
    padding-top: 20px;
  }

  .zoom_wrap .zoom_img01 {
    transform: translate(0, -145px);
  }

  /* .zoom_wrap .zoom_img02{ transform:scale(0.4) translateY(10%); transform-origin:50% 0 0; } */
  .zoom_wrap .zoom_img03 {
    animation: show_pakg 10s infinite alternate linear both;
  }

  @keyframes show_pakg {
    from {
      transform: translateY(50px);
    }

    to {
      transform: translateY(-60%);
    }
  }
}

.popup-block::-webkit-scrollbar {
  display: none;
}

/*popup-block의 스크롤이 없도록 적용 - doun*/

.eapps-instagram-feed-popup a {
  display: inline;
}

div#eapps-tiktok-feed-f8758c32-d46f-4363-9379-ff0f021c2405 > div {
  padding: 0;
}

/*
.eapps-instagram-feed-content {
	height: 28vw;
	overflow-y: scroll;
	overflow-x: hidden;
	overflow-x: -moz-hidden-unscrollable;
}

@media all and (max-width: 767px) {
	.eapps-instagram-feed-content {
		height: 55vw;
	}
}

@media all and (min-width: 768px) and (max-width: 1280px) {
	.eapps-instagram-feed-content {
		height: 59vw;
	}
}*/

div[data-elfsight-lib-ui-portal] a {
  display: inline;
}

.tiktok-embed {
  margin: 0 !important;
  background: transparent !important;
}

.tiktok-embed :not(iframe) {
  display: none;
}
